import React from 'react';
import { Link } from "gatsby";
import * as BS from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

const MarketBadge = (props) => {

  const isMarket = () => {
    if (props.market === 'fintech') {
      return (
        <Link to={`/market/fintech/`}><BS.Badge variant="primary">Fintech</BS.Badge></Link>
      )
    }

    if (props.market === 'healthtech') {
      return (
        <Link to={`/market/healthtech/`}><BS.Badge variant="info">Healthtech</BS.Badge></Link>
      )
    }

    if (props.market === 'martech') {
      return (
        <Link to={`/market/martech/`}><BS.Badge variant="success">Martech</BS.Badge></Link>
      )
    }

    if (props.market === 'hrtech') {
      return (
        <Link to={`/market/hrtech/`}><BS.Badge variant="warning">HRtech</BS.Badge></Link>
      )
    }

    if (props.market === 'construction') {
      return (
        <Link to={`/market/construction/`}><BS.Badge variant="warning">Construction</BS.Badge></Link>
      )
    }

    if (props.market === 'VR') {
      return (
        <Link to={`/market/VR/`}><BS.Badge variant="danger">VR</BS.Badge></Link>
      )
    }

    if (props.market === 'cyber security') {
      return (
        <Link to={`/market/cyber-security/`}><BS.Badge variant="danger">Cyber Security</BS.Badge></Link>
      )
    }

    if (props.market === 'hardware') {
      return (
        <Link to={`/market/hardware/`}><BS.Badge variant="danger">Hardware</BS.Badge></Link>
      )
    }

    if (props.market === 'AI') {
      return (
        <Link to={`/market/ai/`}><BS.Badge variant="danger">AI</BS.Badge></Link>
      )
    }

    if (props.market === 'Gaming') {
      return (
        <Link to={`/market/gaming/`}><BS.Badge variant="danger">Gaming</BS.Badge></Link>
      )
    }

    if (props.market === 'biotech') {
      return (
        <Link to={`/market/biotech/`}><BS.Badge variant="info">Biotech</BS.Badge></Link>
      )
    }

    if (props.market === 'business software') {
      return (
        <Link to={`/market/business-software/`}><BS.Badge variant="warning">Business Software</BS.Badge></Link>
      )
    }

    if (props.market === 'insurtech') {
      return (
        <Link to={`/market/insurtech/`}><BS.Badge variant="primary">Insurtech</BS.Badge></Link>
      )
    }

    if (props.market === 'cars') {
      return (
        <Link to={`/market/cars/`}><BS.Badge variant="dark">Cars</BS.Badge></Link>
      )
    }

    if (props.market === 'freighttech') {
      return (
        <Link to={`/market/freighttech/`}><BS.Badge variant="dark">Freighttech</BS.Badge></Link>
      )
    }

    if (props.market === 'data') {
      return (
        <Link to={`/market/data/`}><BS.Badge variant="danger">Data</BS.Badge></Link>
      )
    }

    if (props.market === 'drone') {
      return (
        <Link to={`/market/drone/`}><BS.Badge variant="danger">Drone</BS.Badge></Link>
      )
    }

    if (props.market === 'ecommerce') {
      return (
        <Link to={`/market/ecommerce/`}><BS.Badge variant="dark">Ecommerce</BS.Badge></Link>
      )
    }

    if (props.market === 'marketplace') {
      return (
        <Link to={`/market/marketplace/`}><BS.Badge variant="dark">Marketplace</BS.Badge></Link>
      )
    }

    if (props.market === 'edtech') {
      return (
        <Link to={`/market/edtech/`}><BS.Badge variant="dark">Edtech</BS.Badge></Link>
      )
    }

    if (props.market === 'energy') {
      return (
        <Link to={`/market/energy/`}><BS.Badge variant="dark">Energy</BS.Badge></Link>
      )
    }

    if (props.market === 'foodtech') {
      return (
        <Link to={`/market/foodtech/`}><BS.Badge variant="info">Foodtech</BS.Badge></Link>
      )
    }

    if (props.market === 'cannabis') {
      return (
        <Link to={`/market/cannabis/`}><BS.Badge variant="info">Cannabis</BS.Badge></Link>
      )
    }

    if (props.market === 'proptech') {
      return (
        <Link to={`/market/proptech/`}><BS.Badge variant="primary">Proptech</BS.Badge></Link>
      )
    }










  }

  return (
    <span>
      { isMarket()}
    </span>
  )
}




export default MarketBadge

